export default {
    state: {
        kioskListDimensions: {},
        windowSize: null,
        kioskAnalyticsDimension: {},
        avatarConfigDimension: {},
        configureKioskDimension: {},
        configureClientDimension: {},
        addNewKioskDialogDimension: {},
        selectAvatarViewerDimension: {}
        
    },
    getters: {
        windowSize: state => state.windowSize,
        kioskListDimensions: state => state.kioskListDimensions,
        kioskAnalyticsDimension: state => state.kioskAnalyticsDimension,
        avatarConfigDimension: state => state.avatarConfigDimension,
        configureKioskDimension: state => state.configureKioskDimension,
        configureClientDimension: state => state.configureClientDimension,
        addNewKioskDialogDimension: state => state.addNewKioskDialogDimension,
        selectAvatarViewerDimension: state => state.selectAvatarViewerDimension
    },
    mutations: {
        setWindowSize(state, size) {
            state.windowSize = size
        },
        setKioskListDimensions(state, dimension) {
            state.kioskListDimensions = dimension
        },
        setKioskAnalyticsDimension(state, dimension) {
            state.kioskAnalyticsDimension = dimension
        },
        setAvatarConfigDimension(state, dimension) {
            state.avatarConfigDimension = dimension
        },
        setConfigureKioskDimension(state, dimension) {
            state.configureKioskDimension = dimension
        },
        setConfigureClientDimension(state, dimension) {
            state.configureClientDimension = dimension
        },
        setNewKioskDialogDimension(state, dimension) {
            state.addNewKioskDialogDimension = dimension
        },
        setSelectAvatarViewerDimension(state, dimension){
            state.selectAvatarViewerDimension = dimension
        }
    },
    actions: {
        appDimensions({ commit }, size) {
            commit('setWindowSize', size)
            switch (size) {
                case "xs":
                    //router.push("/errorView")
                    break;
                case "sm":
                    //router.push("/errorView")
                    break;
                case "md":
                    commit('setKioskListDimensions', {
                        cols: 3,
                    })
                    commit('setKioskAnalyticsDimension', {
                        qnaAnalyticsheight: (window.innerHeight * 58) / 100,
                        userSessionheight: (window.innerHeight * 52) / 100,
                        chartHeight: (window.innerHeight * 58) / 100,
                        reportBarChartDimension: {
                            width: 425,
                            height: 325
                        },
                        pieChartDimension: {
                            width: 325,
                            height: 300
                        },
                        languageBarChartDimension: {
                            width: 250,
                            height: 325
                        },
                    })
                    commit('setAvatarConfigDimension', {
                        innerCardHeight: (window.innerHeight * 43) / 100,
                        outerCardHeight: (window.innerHeight * 85) / 100,
                        avatarWidth: 450
                    })
                    commit('setSelectAvatarViewerDimension', {
                        innerCardHeight: (window.innerHeight * 60) / 100,
                        outerCardHeight: (window.innerHeight * 75) / 100,
                        avatarWidth: 450
                    })
                    commit('setConfigureKioskDimension', {
                        outerCardHeight: (window.innerHeight * 65) / 100,
                        innerCardHeight: (window.innerHeight * 48) / 100
                    })
                    commit('setConfigureClientDimension', {
                        outerCardHeight: (window.innerHeight * 65) / 100,
                        innerCardHeight: (window.innerHeight * 48) / 100
                    })
                    commit('setNewKioskDialogDimension', {
                        height: (window.innerHeight * 70) / 100
                    })
                    break;
                case "lg":
                    commit('setKioskListDimensions', {
                        cols: 3,
                    })
                    commit('setKioskAnalyticsDimension', {
                        qnaAnalyticsheight: (window.innerHeight * 58) / 100,
                        userSessionheight: (window.innerHeight * 52) / 100,
                        chartHeight: (window.innerHeight * 58) / 100,
                        reportBarChartDimension: {
                            width: 425,
                            height: 325
                        },
                        pieChartDimension: {
                            width: 325,
                            height: 325
                        },
                        languageBarChartDimension: {
                            width: 250,
                            height: 325
                        },
                    })
                    commit('setAvatarConfigDimension', {
                        innerCardHeight: (window.innerHeight * 43) / 100,
                        outerCardHeight: (window.innerHeight * 85) / 100,
                        avatarWidth: 450
                    })
                    commit('setSelectAvatarViewerDimension', {
                        innerCardHeight: (window.innerHeight * 60) / 100,
                        outerCardHeight: (window.innerHeight * 75) / 100,
                        avatarWidth: 450
                    })
                    commit('setConfigureKioskDimension', {
                        outerCardHeight: (window.innerHeight * 65) / 100,
                        innerCardHeight: (window.innerHeight * 48) / 100
                    })
                    commit('setConfigureClientDimension', {
                        outerCardHeight: (window.innerHeight * 65) / 100,
                        innerCardHeight: (window.innerHeight * 48) / 100
                    })
                    commit('setNewKioskDialogDimension', {
                        height: (window.innerHeight * 75) / 100
                    })
                    break;
                case "xl":
                    commit('setKioskListDimensions', {
                        cols: 2,
                    })
                    commit('setKioskAnalyticsDimension', {
                        qnaAnalyticsheight: (window.innerHeight * 58) / 100,
                        chartHeight: (window.innerHeight * 52) / 100,
                        userSessionheight: (window.innerHeight * 58) / 100,
                        reportBarChartDimension: {
                            width: 450,
                            height: 375
                        },
                        pieChartDimension: {
                            width: 325,
                            height: 325
                        },
                        languageBarChartDimension: {
                            width: 300,
                            height: 400
                        }
                    })
                    commit('setAvatarConfigDimension', {
                        innerCardHeight: (window.innerHeight * 48) / 100,
                        outerCardHeight: (window.innerHeight * 80) / 100,
                        avatarWidth: 450
                    })
                    commit('setSelectAvatarViewerDimension', {
                        innerCardHeight: (window.innerHeight * 60) / 100,
                        outerCardHeight: (window.innerHeight * 75) / 100,
                        avatarWidth: 450
                    })
                    commit('setConfigureKioskDimension', {
                        outerCardHeight: (window.innerHeight * 80) / 100,
                        innerCardHeight: (window.innerHeight * 60) / 100
                    })
                    commit('setConfigureClientDimension', {
                        outerCardHeight: (window.innerHeight * 65) / 100,
                        innerCardHeight: (window.innerHeight * 48) / 100
                    })
                    commit('setNewKioskDialogDimension', {
                        height: (window.innerHeight * 75) / 100
                    })
                    break;
                case "xxl":
                    commit('setKioskListDimensions', {
                        cols: 2,
                    })
                    break;
                default:
                    commit('setLoginDimension', {
                        width: (window.innerWidth * 23) / 100,
                    })
            }
        }
    }
}